import { cn } from "@/lib/utils";
import { useTranslations } from "next-intl";

export default function ContactSupport({ className }: { className?: string }) {
  const t = useTranslations("ContactSupport");
  return (
    <p className={cn("text-sm text-muted my-4", className)}>
      {t("message")}
      <a
        className="text-foreground"
        href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}
      >
        {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}
      </a>
    </p>
  );
}

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.24.0_next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Ubuntu/Ubuntu-Bold.ttf\",\"weight\":\"700\",\"style\":\"bold\"},{\"path\":\"./Ubuntu/Ubuntu-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./Ubuntu/Ubuntu-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./Ubuntu/Ubuntu-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--ubuntu-display\"}],\"variableName\":\"UbuntuFont\"}");
;
import(/* webpackMode: "eager" */ "/app/public/assets/partners/binance.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/partners/bybit.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/partners/coinbase.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/partners/kucoin.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/partners/mexc.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ChatwootWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionTitle"] */ "/app/src/components/ui/SectionTitle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/providers/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/providers/SWRProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");

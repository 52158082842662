"use client";

import Image from "next/image";
import { Link } from "@/i18n/routing";
import { useTranslations } from "next-intl";
import ContactSupport from "@/components/ContactSupport";
import { UsefulLink } from "@/types";

import { ArrowUpRight } from "lucide-react";
import Logo from "/public/assets/logos/logo.svg";
import XIcon from "/public/assets/social/x.com.svg";
import InstagramIcon from "/public/assets/social/instagram.com.svg";
import TelegramIcon from "/public/assets/social/telegram.svg";

const socialNetworks = [
  {
    href: "https://x.com/kroozzle",
    alt: "Kroozzle on X.com",
    src: XIcon,
    ariaLabel: "Follow Kroozzle on X.com",
  },
  {
    href: "https://www.instagram.com/kroozzle",
    alt: "Kroozzle on Instagram",
    src: InstagramIcon,
    ariaLabel: "Follow Kroozzle on Instagram",
  },
  {
    href: "https://t.me/kroozzle",
    alt: "Kroozzle News on Telegram",
    src: TelegramIcon,
    ariaLabel: "Follow Kroozzle on Telegram",
  },
];

export default function Footer() {
  const t = useTranslations("Footer");
  const currentYear = new Date().getFullYear();
  const usefulLinks: UsefulLink[] = t.raw("usefulLinks");

  return (
    <footer className="container flex flex-col md:flex-row gap-9 pt-8 mb-32">
      <div className="flex flex-col gap-8 order-1 justify-start">
        <div className="flex flex-row gap-8">
          <Link href="/">
            <Image
              className="min-w-[140px] min-h-[48px]"
              alt="Kroozzle Logo"
              src={Logo}
              width={320}
              height={64}
              loading="lazy"
            />
          </Link>
        </div>

        <ContactSupport className="order-3 md:order-2 my-0" />
      </div>

      <div className="w-full order-2 md:order-3 flex flex-col gap-4">
        <nav aria-label="Social Media Links">
          <ul className="flex w-full gap-2">
            {socialNetworks.map(({ alt, href, src, ariaLabel }) => (
              <li
                className="hover:text-primary"
                key={`${alt}-${ariaLabel}-social`}
              >
                <Link
                  href={href}
                  className="flex items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={ariaLabel}
                >
                  <Image
                    src={src}
                    alt={alt}
                    className="h-8 w-8"
                    aria-hidden={false}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <nav aria-label="Useful Links">
          <ul className="flex flex-col justify-between w-fit md:items-end gap-2 leading-5 text-sm lg:text-base text-muted md:text-end">
            {usefulLinks.map(({ text, href, target }) => (
              <li
                className="hover:text-primary transition-colors ease-in-out relative group w-full"
                key={text}
              >
                <Link href={href} className="flex items-center" target={target}>
                  {text}
                  <ArrowUpRight
                    className="inline ml-1 transform transition-[transform] group-hover:translate-x-1 group-hover:-translate-y-1"
                    aria-label="Click to open the new link"
                    size={16}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <p
          className="text-foreground w-full text-start"
          aria-label={`Copyright ${currentYear} Kroozzle. All rights reserved.`}
        >
          {t("rightsReserved", { year: currentYear })}
        </p>
      </div>
    </footer>
  );
}

"use client";

import React from "react";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import { Link } from "@/i18n/routing";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import UserButton from "@/components/ui/user-button";
import VisibleInView from "@/animations/VisibleInView";
import Logo from "/public/assets/logos/logo.svg";
import { logout } from "@/lib/auth/logout";
import Burger from "/public/assets/burger.svg";
import LanguageToggle from "@/components/ui/language-toggle";
import { useTranslations } from "next-intl";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogTitle,
} from "@/components/ui/dialog";
import Indicator from "@/components/ui/indicator";
import { useUser } from "@/services/queries";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { UsefulLink } from "@/types";
import { useSession } from "next-auth/react";

export default function Header() {
  const t = useTranslations("Common");
  const { data: session } = useSession();
  const { data: me } = useUser(session?.user?.id);

  const tLinks = useTranslations("Footer");
  const usefulLinks: UsefulLink[] = tLinks.raw("usefulLinks");
  const newsLink = usefulLinks.find((link) => link.label === "news");

  return (
    <header className="container pt-[22px] md:pt-[38px] mb-8 md:mb-11 bg-transparent ">
      <VisibleInView>
        <nav className="flex justify-between items-center">
          <Link href="/">
            <Image
              alt="logo"
              src={Logo}
              width={140}
              height={48}
              loading="lazy"
            />
          </Link>

          <Dialog>
            <DialogTrigger asChild>
              <Button className="md:hidden" size="icon" variant="secondary">
                <Image alt="menu" src={Burger} width={20} height={20} />
              </Button>
            </DialogTrigger>
            <DialogContent
              aria-describedby="open menu dialog"
              style={{ borderBlock: "none", borderLeft: "none" }}
              className="h-full w-[300px] fixed left-0 translate-x-0 px-4 py-[22px]"
            >
              <VisuallyHidden>
                <DialogTitle>open menu</DialogTitle>
              </VisuallyHidden>
              <nav className="flex flex-col items-start gap-6 p-4">
                <Link className="h-fit mb-6" href="/">
                  <Image alt="logo" src={Logo} width={140} height={48} />
                </Link>
                <LanguageToggle />

                {me ? (
                  <>
                    <Link className="w-fit text-foreground" href="/account">
                      {t("Account")}
                    </Link>

                    <p
                      className="w-fit text-foreground bg-transparent p-0"
                      onClick={async () => {
                        try {
                          await logout();
                          window.location.reload();
                        } catch (error) {
                          // eslint-disable-next-line no-console
                          console.error(error);
                        }
                      }}
                    >
                      {t("Logout")}
                    </p>
                    {newsLink && (
                      <Link
                        className="rounded-lg w-fit text-foreground text-center"
                        href={newsLink.href}
                        target={newsLink.target}
                      >
                        {newsLink.text}
                      </Link>
                    )}
                  </>
                ) : (
                  <Link href="/signin">
                    <Button variant="default">{t("SignIn")}</Button>
                  </Link>
                )}
              </nav>
            </DialogContent>
          </Dialog>

          <div className="hidden md:flex items-center gap-2">
            <div className="flex gap-2">
              {/* <ModeToggle /> */}
              <LanguageToggle />
            </div>

            {me ? (
              <DropdownMenu>
                <DropdownMenuTrigger className="ring-transparent focus-visible:outline-none">
                  <UserButton />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem className="relative">
                    <Link className="w-full text-foreground" href="/account">
                      {t("Account")}
                    </Link>
                    {me.data.mustChangePassword && <Indicator className="right-2" />}
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    {newsLink && (
                      <Link href={newsLink.href} target={newsLink.target}>
                        {newsLink.text}
                      </Link>
                    )}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={async () => {
                      try {
                        await logout();
                        window.location.reload();
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                  >
                    {t("Logout")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Link href="/signin">
                <Button variant="default">{t("SignIn")}</Button>
              </Link>
            )}
          </div>
        </nav>
      </VisibleInView>
    </header>
  );
}
